import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { useMediaQuery, Context as ResponsiveContext } from "react-responsive"

// Components
import { Layout } from "../components/Layout"
import SEO from "../components/seo"
import { ScrollDownButton } from "../components/ScrollDownButton"
import FooterCTA from "../components/FooterCTA"
import BannerShapeServices from "../components/BannerShapeServices"

// Custom Styles & Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from "react-bootstrap/Carousel"
import styles from "./services.module.scss"

// Content
import JSONData from "../../content/other-services.json"

const OtherServicesPage = ({ data }) => {
  const [index, setIndex] = useState(0)

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex)
  }

  const bgBtnSrc = data.slideBtnBg.publicURL

  const { banner_title, banner_text, carousel_items } = JSONData

  const isMax575px = useMediaQuery({ maxWidth: 575 })
  const isMinWidth1092px = useMediaQuery({ minWidth: 1092 })

  return (
    <Layout>
      <SEO
        title={banner_title}
        description={banner_text}
        image={data.seoBanner.childImageSharp.resize}
      />
      <Container
        fluid
        className={styles.container}
        css={css`
          @media (min-width: 576px) {
            background-image: url(${data.bannerBackground.publicURL});
          }
        `}
      >
        <ResponsiveContext.Provider value={{ maxWidth: 575 }}>
          <BannerShapeServices />
        </ResponsiveContext.Provider>
        <Container className={styles.bannerContainer}>
          <Row className={styles.bannerRow}>
            {isMax575px ? (
              <Col>
                <h1>{banner_title}</h1>
              </Col>
            ) : (
              <>
                <Col>
                  <h1>{banner_title}</h1>
                  <p>{banner_text}</p>
                  <ScrollDownButton
                    name="Learn More"
                    sectionId="#services-slider"
                  />
                </Col>
                <Col>
                  <Img
                    fluid={data.banner.childImageSharp.fluid}
                    alt={banner_title}
                  />
                </Col>
              </>
            )}
          </Row>
        </Container>
      </Container>
      <Container
        id="services-slider"
        fluid
        className={styles.bodyBg}
        css={css`
          @media (min-width: 576px) {
            background-image: url(${data.waveBackground.publicURL});
          }
        `}
      >
        <Container className="slide-container">
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            controls={false}
            fade={true}
            touch={true}
            slide={false}
            className="carousel-container"
          >
            {carousel_items.map((item, i) => (
              <Carousel.Item className="slide-item" key={i}>
                <div className="img-container">
                  <Img
                    fluid={data[item.slide_img].childImageSharp.fluid}
                    className="d-block w-100"
                    alt={item.container_title}
                  />
                </div>
                <div className="desc-container">
                  <h3>{item.container_title}</h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: item.container_text }}
                  />
                  <ul dangerouslySetInnerHTML={{ __html: item.container_ul }} />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          {isMinWidth1092px && (
            <div className="card-control-container">
              {carousel_items.map((item, i) => (
                <button
                  key={i}
                  className={index === i ? "card-btn btn-bg" : "card-btn"}
                  css={
                    index === i
                      ? css`
                      background-image: url('${bgBtnSrc}');`
                      : ""
                  }
                  onClick={() => handleSelect(i)}
                >
                  {index === i ? (
                    <Img
                      fluid={data[item.btn_icon_hovered].childImageSharp.fluid}
                      className="card-btn-icon"
                      objectFit="cover"
                      objectPosition="50% 50%"
                      alt={item.container_title}
                    />
                  ) : (
                    <Img
                      fluid={data[item.btn_icon].childImageSharp.fluid}
                      className="card-btn-icon"
                      objectFit="cover"
                      objectPosition="50% 50%"
                      alt={item.container_title}
                    />
                  )}
                </button>
              ))}
            </div>
          )}
        </Container>
      </Container>
      <FooterCTA />
    </Layout>
  )
}

export default OtherServicesPage

export const query = graphql`
  query {
    seoBanner: file(relativePath: { eq: "banner/seo-home-banner.png" }) {
      publicURL
      childImageSharp {
        resize(width: 1024) {
          src
          height
          width
        }
      }
    }

    bannerBackground: file(relativePath: { eq: "banner/background.svg" }) {
      publicURL
    }

    banner: file(relativePath: { eq: "services/other-services.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 720) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    waveBackground: file(relativePath: { eq: "services/wave-bg.svg" }) {
      publicURL
    }

    # images for slides
    service01: file(relativePath: { eq: "services/other-services/01.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service02: file(relativePath: { eq: "services/other-services/02.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service03: file(relativePath: { eq: "services/other-services/03.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service04: file(relativePath: { eq: "services/other-services/04.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    # slide card icons
    slideBtnBg: file(relativePath: { eq: "home/card-bg.svg" }) {
      publicURL
    }

    icon01: file(relativePath: { eq: "services/other-services/01-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon01Hover: file(
      relativePath: { eq: "services/other-services/01-icon-hover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon02: file(relativePath: { eq: "services/other-services/02-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon02Hover: file(
      relativePath: { eq: "services/other-services/02-icon-hover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon03: file(relativePath: { eq: "services/other-services/03-icon.png" }) {
      childImageSharp {
        fluid(maxHeight: 144) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon03Hover: file(
      relativePath: { eq: "services/other-services/03-icon-hover.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 144) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon04: file(relativePath: { eq: "services/other-services/04-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    icon04Hover: file(
      relativePath: { eq: "services/other-services/04-icon-hover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
